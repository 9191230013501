<div
  class="overlay"
  (click)="toggleSidenav.emit()"
  *ngIf="isTouch$ | async"
></div>

<div class="wrapper">
  <section>
    <ng-container *ngIf="(isTouch$ | async) === false">
      <cf-icon
        *ngIf="!pinned"
        icon="pin"
        class="pin"
        (click)="pinNavigation()"
      ></cf-icon>
      <cf-icon
        size="sm"
        *ngIf="pinned"
        icon="double_arrow_back"
        class="pin"
        (click)="immediatelyClose()"
      ></cf-icon>
    </ng-container>

    <cf-icon
      *ngIf="isTouch$ | async"
      icon="close"
      class="pin"
      color="grey-70"
      size="sm"
      (click)="toggleSidenav.emit()"
    ></cf-icon>

    <div
      cf-main-logo
      size="sm"
      logoHeight="32"
      class="mt-xl-3 logo-sm"
      routerLink="/dashboard"
      [queryParamsHandling]="getQueryParamsHandling('/dashboard')"
      (click)="closeSidenav()"
    ></div>
    <div
      cf-main-logo
      size="lg"
      logoHeight="32"
      class="mt-xl-3 logo-lg"
      (click)="closeSidenav()"
      routerLink="/dashboard"
      [queryParamsHandling]="getQueryParamsHandling('/dashboard')"
    ></div>

    <div class="line d-none"></div>

    <cf-create-button
      *ngIf="isAuthorized()"
      class="w-100 mt-3"
      [fullWidth]="!!(isTouch$ | async)"
      (emitNavigate)="closeSidenav()"
    ></cf-create-button>

    <div class="menu-items mt-4" *ngIf="isAuthorized()">
      <a
        cf-link
        size="sm"
        color="grey-80"
        routerLink="/dashboard"
        routerLinkActive="active"
        [queryParamsHandling]="getQueryParamsHandling('/dashboard')"
        (click)="closeSidenav()"
      >
        <cf-icon icon="house" color="grey-80"></cf-icon>
        <span class="item">
          {{ 'dashboard' | translate }}
        </span>
      </a>
      <a
        cf-link
        size="sm"
        color="grey-80"
        routerLink="/events/list"
        routerLinkActive="active"
        [queryParamsHandling]="getQueryParamsHandling('/events/list')"
        (click)="closeSidenav()"
      >
        <cf-icon icon="calendar" color="grey-80"></cf-icon>
        <span class="item">
          {{ 'events.title' | translate }}
        </span>
      </a>
      <a
        cf-link
        size="sm"
        color="grey-80"
        routerLink="/group-dashboard"
        routerLinkActive="active"
        [queryParamsHandling]="getQueryParamsHandling('/group-dashboard')"
        (click)="closeSidenav()"
      >
        <cf-icon icon="documents" color="grey-80"></cf-icon>
        <span class="item">
          {{ 'groups' | translate }}
        </span>
      </a>
    </div>

    <div class="line d-xl-none"></div>

    <div class="menu-items mt-xl-auto" *ngIf="isAuthorized()">
      <a
        cf-link
        size="sm"
        color="grey-80"
        routerLink="/availability"
        routerLinkActive="active"
        [queryParamsHandling]="getQueryParamsHandling('/availability')"
        (click)="closeSidenav()"
      >
        <cf-icon icon="clipboard" color="grey-80"></cf-icon>
        <span class="item">
          {{ 'schedules.booking_schedules' | translate }}
        </span>
      </a>

      <div class="line"></div>

      <a
        cf-link
        size="sm"
        color="grey-80"
        routerLink="/integrations/list"
        routerLinkActive="active"
        [queryParamsHandling]="'merge'"
        [queryParams]="{ type: '' }"
        (click)="closeSidenav()"
      >
        <cf-icon icon="grid" color="grey-80"></cf-icon>
        <span class="item">{{ 'integration.title' | translate }}</span>
      </a>
      <a
        cf-link
        size="sm"
        color="grey-80"
        routerLink="/integrations/list"
        routerLinkActive="active"
        [queryParamsHandling]="'merge'"
        [queryParams]="{ type: 'calendars' }"
        (click)="closeSidenav()"
      >
        <cf-icon icon="calendars" color="grey-80"></cf-icon>
        <span class="item">{{ 'calendars.title' | translate }}</span>
      </a>
      <a
        cf-link
        size="sm"
        color="grey-80"
        routerLink="/integrations/list"
        routerLinkActive="active"
        [queryParamsHandling]="'merge'"
        [queryParams]="{ type: 'meeting_tools' }"
        (click)="closeSidenav()"
      >
        <cf-icon icon="video" color="grey-80"></cf-icon>
        <span class="item">{{ 'header.meeting_tools' | translate }}</span>
      </a>
    </div>

    @if (isTouch$ | async) {
    <div class="mt-auto">
      @if ((accountService.account$ | async); as account) {
      <div class="d-flex align-items-center">
        <cf-avatar [src]="account.profile_picture"></cf-avatar>
        <cf-text
          class="ms-2"
          className="text-cut text-break-all max-1"
          size="sm"
          >{{ account.name }}</cf-text
        >
      </div>

      <div class="menu-items mt-1">
        <a
          cf-link
          size="sm"
          color="grey-80"
          routerLink="/settings/profile"
          routerLinkActive="active"
          (click)="closeSidenav()"
        >
          <cf-icon icon="user" color="grey-80"></cf-icon>
          <span class="item">
            {{ 'header.my_profile' | translate }}
          </span>
        </a>

        <cf-text
          class="pointer mt-3"
          size="sm"
          color="red-40"
          (click)="logout(); closeSidenav()"
        >
          <cf-icon class="me-2" icon="logout" color="red-40"></cf-icon>
          <span>{{ 'header.logout' | translate }}</span>
        </cf-text>
      </div>
      }
    </div>
    }
  </section>
</div>
