import { EnvironmentData } from '@cf/core';

export const environment: EnvironmentData = {
  production: true,
  enabledNamespaces: [],
  apiUrl: 'https://api.stg.calfrenzy.bitstudios.dev/',
  appUrl: 'https://stg.calfrenzy.bitstudios.dev',
  publicUrl: 'https://stg.calfrenzy.bitstudios.dev/',
  bookingUrl: 'https://stg.calfrenzy.co/',
  stripeId:
    'pk_live_51Pv0E5Kj2WT4T8Fn0O8oK4BdN1ZhfhI4mVTcESjQP2wLNy5W2B2U5ZD3rj6hnfSlfXaSl9bfReWFSbrDREBWHGuk00cRSmUlQc',
  domain: '',
  envName: 'stg',
  googleClientId:
    '369573383535-phvu70n2klaevh7vh1s0u3pjrkgl5goc.apps.googleusercontent.com',
  googleMapId: 'AIzaSyCMQC8InSaMfLqxtPGumLRWGEQTD8gHuhs',
  zoomClientId: 'BvATuJS_RJGIzYSNBxQKA',
  buildVersion: '47b6776abeecd1280bc8bc4903a394970aaa8ac1',
};
