import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authorizedGuard, superAdminGuard } from '@cf/features/auth/shell';
import { legacyURLredirectionGuard } from '@cf/features/booking';

const routes: Routes = [
  {
    path: 'stats',
    canActivate: [superAdminGuard],
    loadChildren: () =>
      import('@cf/features/admin-statistic').then(
        (m) => m.featureStatisticRoutes
      ),
  },

  {
    path: 'sandbox',
    loadChildren: () =>
      import('@cf/features/admin-statistic').then(
        (m) => m.featureStatisticRoutes
      ),
  },

  {
    path: 'auth',
    loadChildren: () => import('./libs/auth').then((m) => m.AuthModule),
    canActivate: [authorizedGuard],
  },

  {
    path: 'home',
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
  },

  {
    path: 'default',
    loadChildren: () =>
      import('./modules/routing/default/default.module').then(
        (m) => m.DefaultModule
      ),
  },

  {
    path: '',
    loadChildren: () =>
      import('./modules/routing/app-container/app-container.module').then(
        (m) => m.AppContainerModule
      ),
    // canActivate: [authGuard],
  },

  {
    path: ':username',
    loadChildren: () =>
      import('./modules/routing/booking/booking.module').then(
        (m) => m.BookingModule
      ),
    canActivate: [legacyURLredirectionGuard],
  },

  {
    path: 'b/:username',
    loadChildren: () =>
      import('./modules/routing/booking/booking.module').then(
        (m) => m.BookingModule
      ),
    canActivate: [legacyURLredirectionGuard],
  },

  {
    path: 'booking',
    loadChildren: () =>
      import('./modules/routing/booking/booking.module').then(
        (m) => m.BookingModule
      ),
    canActivate: [legacyURLredirectionGuard],
  },

  {
    path: 'error/:statusCode',
    loadChildren: () =>
      import('./modules/routing/error/error.module').then((m) => m.ErrorModule),
  },

  {
    path: '**',
    redirectTo: 'error/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
