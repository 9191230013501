import { inject } from '@angular/core';
import { openScheduleSelection } from '@cf/temp/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { createEffect, ofType } from '@ngneat/effects';
import { debounceTime, tap } from 'rxjs/operators';
import { ScheduleSelectionModal } from '../schedule-selection/schedule-selection.modal';

export class DialogEffects {
  modalService = inject(NgbModal);

  scheduleSelection$ = createEffect(
    (actions) =>
      actions.pipe(
        ofType(openScheduleSelection),
        debounceTime(500),
        tap(({ token, byToken }) => {
          const modal = this.modalService.open(ScheduleSelectionModal);
          modal.componentInstance.token = token;
          modal.componentInstance.byToken = byToken;
        })
      ),
    {
      dispatch: false,
    }
  );
}
