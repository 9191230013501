import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  input,
  OnInit,
  output,
  ViewEncapsulation,
} from '@angular/core';
import {
  QueryParamsHandling,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { ResponsiveService } from '@cf/core';
import { ButtonModule } from '@cf/shared/ui/button';
import { IconModule } from '@cf/shared/ui/icons';
import { LogosModule } from '@cf/shared/ui/logos';
import { TypographyModule } from '@cf/shared/ui/typography';
import { AccountService, AuthService } from '@cf/temp/services';
import { TranslateModule } from '@ngx-translate/core';
import { CreateButtonComponent } from '../create-button/create-button.component';

@Component({
  selector: 'cf-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    IconModule,
    LogosModule,
    RouterLink,
    ButtonModule,
    RouterLinkActive,
    TranslateModule,
    TypographyModule,
    CreateButtonComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SidenavComponent implements OnInit {
  @HostBinding('class.pinned') pinned!: boolean;

  isAuthorized = input<boolean | null>(false);
  toggleSidenav = output();

  router = inject(Router);
  elementRef = inject(ElementRef);
  authService = inject(AuthService);
  accountService = inject(AccountService);
  responsiveService = inject(ResponsiveService);

  isMobile!: boolean;

  isTouch$ = this.responsiveService.isTouch$;

  private _mouseleave!: ReturnType<typeof setTimeout>;

  @HostListener('mouseenter') onMouseEnter() {
    clearTimeout(this._mouseleave);
    setTimeout(() => {
      this.elementRef.nativeElement.classList.add('hovered');
    }, 150);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this._mouseleave = setTimeout(() => {
      this.elementRef.nativeElement.classList.remove('hovered');
    }, 150);
  }

  ngOnInit() {
    this.checkPinnedState();

    this.isTouch$.subscribe((isTouch) => {
      if (isTouch) {
        this.pinned = false;
      } else {
        this.checkPinnedState();
      }
    });
  }

  getQueryParamsHandling(route: string): QueryParamsHandling {
    if (this.router.url.split('?')[0] === route) {
      return 'preserve';
    }
    return '';
  }

  closeSidenav() {
    if (this.isMobile) {
      this.toggleSidenav.emit();
    } else {
      if (!this.pinned) {
        this.immediatelyClose();
      }
    }
  }

  logout() {
    this.authService.logout();
  }

  immediatelyClose() {
    setTimeout(() => {
      this.unpinNavigation();
      this.elementRef.nativeElement.classList.remove('hovered');
    }, 100);
  }

  pinNavigation() {
    localStorage.setItem('pinned', 'true');
    this.pinned = true;
  }

  unpinNavigation() {
    localStorage.setItem('pinned', 'false');
    this.pinned = false;
  }

  private checkPinnedState() {
    if (!localStorage.getItem('pinned')) {
      this.pinNavigation();
    }
    if (localStorage.getItem('pinned') === 'true') {
      this.pinned = true;
    }
  }
}
